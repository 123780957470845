import { useEffect, useState } from "react";
import MainTable from "./TableElements/MainTable";
import SelectionTable from "./TableElements/SelectionTable";
import MatrixModel from "./Models/MatrixModel";
import Consts from "./Consts";

function MainContainer(_props: any) {
	const [isMainMatrixVisible, setIsMainMatrixVisible] = useState(true);
	const [matrixModel, setMatrixModel] = useState<MatrixModel>(
		new MatrixModel({
			dimension: 3,
			onMainMatrixVisibleChanged: (value: boolean) => setIsMainMatrixVisible(value),
		})
	);

	useEffect(() => {
		matrixModel.generateGame();
		matrixModel.setVisibleNumbers(Consts.complexity[0].value);
	}, [matrixModel]);

	const handleRegenerate: (dimension: number, complexity: number) => void = (dimension, complexity) => {
		const dimensionChanged = matrixModel.dimension !== dimension;
		if (dimensionChanged) {
			setMatrixModel(
				new MatrixModel({
					dimension: dimension,
					onMainMatrixVisibleChanged: (value: boolean) => setIsMainMatrixVisible(value),
				})
			);
		}
		matrixModel.generateGame();
		matrixModel.setVisibleNumbers(complexity);
	};

	return (
		<div>
			<h3>Sudoku</h3>
			{isMainMatrixVisible ? (
				<MainTable key={"main-table-" + matrixModel.dimension} matrixModel={matrixModel} onRegenerate={handleRegenerate} />
			) : (
				<SelectionTable key={"selection-table-" + matrixModel.dimension} matrixModel={matrixModel} />
			)}

			<span>Contact the author <a href="mailto:dmytero@outlook.com">here</a></span>
		</div>
	);
}

export default MainContainer;
