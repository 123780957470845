import { useEffect, useState } from "react";
import MatrixModel from "../Models/MatrixModel";
import Row from "./Row";
import Consts from "../Consts";
import { Button, MenuItem, Select, SelectChangeEvent } from "@mui/material";
import "./MainTable.css";

function MainTable(props: any) {
	const [rows, setRows] = useState<any[]>([]);
	const [matrixModel] = useState<MatrixModel>(props.matrixModel);
	const [selectedComplexity, setSelectedComplexity] = useState<string>(Consts.complexity[0].value.toString());
	const [selectedDimension, setSelectedDimension] = useState<string>(matrixModel.dimension.toString());

	useEffect(() => {
		const dimension = matrixModel.dimension;
		const newRows = [];
		for (let i = 0; i < dimension; i++) {
			for (let j = 0; j < dimension; j++) {
				let rowIndex = i * dimension + j;
				newRows.push(<Row key={"main-table-row" + j + i * 10} index={rowIndex} matrixModel={matrixModel} />);
			}

			newRows.push(
				<tr key={"main-table-line" + i * 100 + 100}>
					<td>
						<div className="horizontalLine" />
					</td>
				</tr>
			);
		}
		setRows(newRows);
	}, [matrixModel]);

	return (
		<div className="main-table-container">
			<div className="regenerate-panel">
				<Select
					className="regenerate-panel-item"
					value={selectedComplexity}
					onChange={(event: SelectChangeEvent) => setSelectedComplexity(event?.target.value ?? Consts.complexity[0].value.toString())}
				>
					{Consts.complexity.map((complexity) => {
						return <MenuItem key={complexity.value} value={complexity.value}>{complexity.label}</MenuItem>;
					})}
				</Select>
				<Select
					className="regenerate-panel-item"
					value={selectedDimension}
					onChange={(event: SelectChangeEvent) => setSelectedDimension(event?.target.value ?? Consts.dimensions[0].value.toString())}
				>
					{Consts.dimensions.map((dimension) => {
						return <MenuItem key={dimension.value} value={dimension.value}>{dimension.label}</MenuItem>;
					})}
				</Select>
				<div className="regenerate-panel-item">
					<Button variant="contained" color="success" onClick={() => props.onRegenerate(+selectedDimension, +selectedComplexity)}>
						Regenerate game
					</Button>
				</div>
			</div>
			<div>
				<table>
					<tbody>{rows}</tbody>
				</table>
			</div>
		</div>
	);
}

export default MainTable;
