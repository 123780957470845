class Consts { 
    public static readonly complexity = [
      { value: 10, label: "easy" },
      { value: 50, label: "challenging" },
      { value: 70, label: "hard" }
    ];
    public static readonly dimensions = [
      { value: 3, label: "9x9" },
      { value: 4, label: "16x16" }
    ];
}

export default Consts;