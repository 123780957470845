import React, { useEffect, useState } from "react";
import CellModel from "../Models/CellModel";
import { Button } from "@mui/material";
import "./Cell.css";

function Cell(props: { model: CellModel; handleClick: () => void }) {
	const [cellModel] = useState<CellModel>(props.model);
	const [realValue, setRealValue] = useState<number>(props.model.realValue);
	const [visibleValue, setVisibleValue] = useState<number>(props.model.visibleValue);
	const [isStatic, setIsStatic] = useState<boolean>(props.model.isStatic);

	useEffect(() => {
		cellModel.onRealValueUpdated = () => {
			setRealValue(cellModel.realValue);
		};
		cellModel.onVisibleValueUpdated = () => {
			setVisibleValue(cellModel.visibleValue);
		};
		cellModel.onIsStaticUpdated = (isStatic: boolean) => {
			setIsStatic(isStatic);
		};
	}, [cellModel]);

	return (
		<td>
			<Button variant={isStatic ? "contained" : "outlined"} onClick={isStatic ? undefined : () => props.handleClick()} className="cell-button">
				{process.env.REACT_APP_MODE === "DEBUG" ? realValue + "##" : ""} {visibleValue === 0 ? "" : visibleValue}
			</Button>
		</td>
	);
}

export default Cell;
