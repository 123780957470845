import { useEffect, useState } from "react";
import MatrixModel from "../Models/MatrixModel";
import "./TableCommon.css";
import "./SelectionTable.css";
import { Button } from "@mui/material";

function SelectionTable(props: any) {
	const [cells, setCells] = useState<any[]>([]);
	const [matrixModel] = useState<MatrixModel>(props.matrixModel);

	useEffect(() => {
		const dimension = matrixModel.dimension;
		const newCells = [];
		let currentNumber = 1;
		for (let row = 0; row < dimension; row++) {
			const newRow = [];
			for (let column = 0; column < dimension; column++) {
				const number = currentNumber;
				newRow.push(
					<td key={"cell" + (column * 10 + row)}>
						<Button variant="contained" onClick={() => matrixModel.handleSelectedClick(number)}>
							{number}
						</Button>
						<div className="verticalLine" />
					</td>
				);
				currentNumber++;
			}
			newCells.push(<tr key={"horizondddtalLine" + row}>{newRow}</tr>);

			const horizontalLineKey = "horizontalLine" + row;
			newCells.push(
				<tr key={horizontalLineKey}>
					<td>
						<div className="horizontalLine" />
					</td>
				</tr>
			);
		}
		setCells(newCells);
	}, [matrixModel]);

	return (
		<div className="selection-table-container">
			<table>
				<tbody>{cells}</tbody>
			</table>
		</div>
	);
}

export default SelectionTable;
