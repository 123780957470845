import MatrixModel from "./MatrixModel";

type Point = { row: number; column: number };

class GameGenerator {
    private readonly useDensity = true;

	generate(matrix: MatrixModel): void {
		let dimension = matrix.dimension;
		let size = dimension * dimension;
		var regenerateCount = 0;
		for (let value = 1; value < size + 1; value++) {
			for (let boxColumn = 0; boxColumn < dimension; boxColumn++) {
				for (let boxRow = 0; boxRow < dimension; boxRow++) {
                    let valueRow: number;
                    let valueColumn: number;
                    if(this.useDensity) {
                        const densities = this.calculateBoxCellDensities(matrix, boxRow, boxColumn);
                        densities.sort((a, b) => a.density - b.density);
                        const minDensity = densities[0].density;
                        const bottomDensities = densities.filter((d) => d.density === minDensity);
                        const match = bottomDensities[Math.floor(Math.random() * bottomDensities.length)];
                        valueRow = match.rowIndex;
                        valueColumn = match.columnIndex;
                    } else {
                        valueRow = Math.floor(Math.random() * 3);
                        valueColumn = Math.floor(Math.random() * 3);
                    }
					let coordinates = this.convertToMatrixCoordinates(matrix.dimension, boxRow, boxColumn, valueRow, valueColumn);

					let generatedCoordinates = coordinates;

					let regenerateValue = false;
					while (!this.canInsert(matrix, value, coordinates)) {
						valueColumn++;
						if (valueColumn >= dimension) {
							valueColumn = 0;
							valueRow++;
						}
						if (valueRow >= dimension) {
							valueRow = 0;
						}
						coordinates = this.convertToMatrixCoordinates(matrix.dimension, boxRow, boxColumn, valueRow, valueColumn);

						if (generatedCoordinates.row === coordinates.row && generatedCoordinates.column === coordinates.column) {
							regenerateValue = true;

							const numberOfTriesBeforeDecidingDeadEnd = 50;
							if (regenerateCount++ > numberOfTriesBeforeDecidingDeadEnd) {
								matrix.clearRealValues();
								regenerateCount = 0;
								value = 1;
							}
							break;
						}
					}

					if (regenerateValue) {
						matrix.clearRealValue(value);
						boxColumn = 0;
						boxRow = -1;
					} else {
						matrix.getCell(coordinates.row, coordinates.column).setRealValue(value);
					}
				}
			}
		}
	}

	private canInsert(matrix: MatrixModel, value: number, coordinates: Point): boolean {
		let size = matrix.dimension * matrix.dimension;

		if (matrix.getCell(coordinates.row, coordinates.column).isInitialized()) {
			return false;
		}

		for (let rowIndex = 0; rowIndex < size && rowIndex !== coordinates.row; rowIndex++) {
			if (matrix.getCell(rowIndex, coordinates.column).realValue === value) {
				return false;
			}
		}

		for (let columnIndex = 0; columnIndex < size && columnIndex !== coordinates.column; columnIndex++) {
			if (matrix.getCell(coordinates.row, columnIndex).realValue === value) {
				return false;
			}
		}

		return true;
	}

	private convertToMatrixCoordinates(dimension: number, boxRow: number, boxColumn: number, row: number, column: number): Point {
		return {
			row: boxRow * dimension + row,
			column: boxColumn * dimension + column,
		};
	}

	private calculateBoxCellDensities(matrix: MatrixModel, boxRow: number, boxColumn: number): { rowIndex: number; columnIndex: number; density: number }[] {
		const size = matrix.dimension * matrix.dimension;
		const densities: { rowIndex: number; columnIndex: number; density: number }[] = [];
		for (let rowIndex = 0; rowIndex < matrix.dimension; rowIndex++) {
			for (let columnIndex = 0; columnIndex < matrix.dimension; columnIndex++) {
				let density = 0;
				const coordinate = this.convertToMatrixCoordinates(matrix.dimension, boxRow, boxColumn, rowIndex, columnIndex);
				for (let row = 0; row < size; row++) {
					if (matrix.getCell(row, coordinate.column).isInitialized()) {
						density++;
					}
				}
				for (let column = 0; column < size; column++) {
					if (matrix.getCell(coordinate.row, column).isInitialized()) {
						density++;
					}
				}
				densities.push({ rowIndex, columnIndex, density });
			}
		}
		return densities;
	}
}

export default GameGenerator;
