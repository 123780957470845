import { useEffect, useState } from "react";
import MatrixModel from "../Models/MatrixModel";
import Cell from "./Cell";
import "./TableCommon.css";

function Row(props: any) {
	const [cells, setCells] = useState<any[]>([]);
	const [matrixModel] = useState<MatrixModel>(props.matrixModel);
	const [index] = useState<number>(props.index);

	useEffect(() => {
		const dimension = matrixModel.dimension;
		const newCells = [];
		for (let row = 0; row < dimension; row++) {
			for (let column = 0; column < dimension; column++) {
				let cellRow = index;
				let cellColumn = row * dimension + column;
				let handleClick = () => {
					matrixModel.handleSelectingClick(cellRow, cellColumn);
				};
				let cellModel = matrixModel.getCell(cellRow, cellColumn);

				newCells.push(<Cell key={"row-cell" + cellModel.realValue + cellModel.visibleValue * 100 + ":" + cellRow + cellColumn * 100} handleClick={handleClick} model={cellModel} />);
			}

			newCells.push(
				<td key={"row-line" + row * 100}>
					<div className="verticalLine" />
				</td>
			);
		}
		setCells(newCells);
	}, [index, matrixModel]);

	return <tr>{cells}</tr>;
}

export default Row;
