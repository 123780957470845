import CellModel from "./CellModel";
import GameGenerator from "./GameGenerator";

class MatrixModel {
	private cells: CellModel[] = [];
	private isGenerating: boolean = false;
	isMainMatrixVisible: boolean = true;
	dimension: number;
	onMainMatrixVisibleChanged: (value: boolean) => void;
	selectedCellModel: CellModel | null = null;

	constructor(props: { dimension: number; onMainMatrixVisibleChanged: (value: boolean) => void }) {
		this.dimension = props.dimension;
		this.onMainMatrixVisibleChanged = props.onMainMatrixVisibleChanged;

		for (let row = 0; row < this.dimension * this.dimension; row++) {
			for (let column = 0; column < this.dimension * this.dimension; column++) {
				this.cells.push(new CellModel({ row: row, column: column }));
			}
		}
	}

	getCell = (row: number, column: number): CellModel => {
		let result = this.cells[row * this.dimension * this.dimension + column];
		return result;
	};

	handleSelectedClick = (selectedNumber: number) => {
		this.selectedCellModel?.setVisibleValue(selectedNumber);
		this.isMainMatrixVisible = !this.isMainMatrixVisible;
		this.selectedCellModel = null;
		this.isWinSituation();

		return this.onMainMatrixVisibleChanged(true);
	};

	handleSelectingClick = (row: number, column: number) => {
		this.isMainMatrixVisible = !this.isMainMatrixVisible;
		this.selectedCellModel = this.getCell(row, column);

		return this.onMainMatrixVisibleChanged(false);
	};

	isWinSituation = () => {
		const reducer = (accumulator: boolean, current: CellModel) => (accumulator ? current.realValue === current.visibleValue : accumulator);
		var allNumbersAreCorrect = this.cells.reduce(reducer, true);
		if (allNumbersAreCorrect) {
			alert("The win you have achieved");
		}
	};

	generateGame = () => {
		if(this.isGenerating) {
			return;
		}
		this.isGenerating = true;
		this.cells.forEach((cell) => {
			cell.setRealValue(0);
			cell.setVisibleValue(0);
			cell.setIsStatic(false);
		});
		new GameGenerator().generate(this);
		this.isGenerating = false;
	};

	setVisibleNumbers(hiddenNumber: number) {
		var numberNumbers = Math.pow(this.dimension, 4);
		var numberOfVisibleNumbers = numberNumbers - hiddenNumber;
		var visibleIndexes: number[] = [];

		for (let i = 0; i < numberOfVisibleNumbers; i++) {
			var index = Math.floor(Math.random() * numberNumbers);
			// eslint-disable-next-line
			while (visibleIndexes.find((vi) => vi === index) !== undefined) {
				index = Math.floor(Math.random() * numberNumbers);
			}
			visibleIndexes.push(index);
		}

		for (let i = 0; i < visibleIndexes.length; i++) {
			var cell = this.cells[visibleIndexes[i]];
			cell.setVisibleValue(cell.realValue);
            cell.setIsStatic(true);
		}
	}

	clearRealValue(value: number) {
		this.cells.forEach((cell) => {
			if (cell.realValue === value) {
				cell.setRealValue(0);
			}
		});
	}

	clearRealValues() {
		this.cells.forEach((cell) => {
			cell.setRealValue(0);
		});
	}
}

export default MatrixModel;
