class CellModel {
	realValue: number = 0;
	visibleValue: number = 0;
	row: number = 0;
	column: number = 0;
	isStatic: boolean = false;
	onRealValueUpdated: () => void = () => {};
	onVisibleValueUpdated: () => void = () => {};
	onIsStaticUpdated: (value: boolean) => void = () => {};

	constructor(props: { row: number; column: number }) {
		this.row = props.row;
		this.column = props.column;
	}

	setRealValue = (value: number): void => {
		this.realValue = value;
		this.onRealValueUpdated();
	};

	setVisibleValue = (value: number): void => {
		this.visibleValue = value;
		this.onVisibleValueUpdated();
	};

	isInitialized() {
		return this.realValue !== 0;
	}

	setIsStatic(value: boolean) {
		this.isStatic = value;
		this.onIsStaticUpdated(this.isStatic);
	}
}

export default CellModel;
